<template>
  <section>
    <p class="text-h6 primary--text">Solicitud de aprobación de proveedor</p>
    <v-row>
      <v-col cols="12" md="4" lg="6">
        <v-row class="mt-1">
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">Nombre del proveedor</p>
            <span>{{ proveedor.nombre }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">
              Identificación tributaria según origen del proveedor
            </p>
            <span>
              {{
                proveedor.id_pais == 68
                  ? `Salvadoreño con ${proveedor.tipo_documento}`
                  : `Extranjero con ${proveedor.tipo_documento}`
              }}
            </span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">Tipo de contribuyente</p>
            <span>{{ proveedor.tipo_contribuyente }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">
              {{ idTributariaHandler }}
            </p>
            <span>{{ proveedor.numero_documento }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">Nombre comercial</p>
            <span>{{
              proveedor.nombre_comercial ? proveedor.nombre_comercial : ""
            }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">Sitio web</p>
            <span>{{
              proveedor.sitio_web ? proveedor.sitio_web : "No posee"
            }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">Género</p>
            <span>{{ proveedor.genero }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">Clasificación empresarial</p>
            <span>{{ proveedor.empresa_clasificacion }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">País de origen</p>
            <span>{{ proveedor.pais_direccion }}</span>
          </v-col>
          <v-col
            cols="12"
            md="12"
            v-if="
              proveedor.id_identificacion_tributaria !== 5 &&
              proveedor.id_pais === 68
            "
          >
            <p class="mb-0 grey--text">Departamento</p>
            <span>{{ proveedor.departamento }}</span>
          </v-col>
          <v-col
            cols="12"
            md="12"
            v-if="
              proveedor.id_identificacion_tributaria !== 5 &&
              proveedor.id_pais === 68
            "
          >
            <p class="mb-0 grey--text">Municipio</p>
            <span>{{ proveedor.municipio }}</span>
          </v-col>
          <v-col cols="12" md="12">
            <p class="mb-0 grey--text">Dirección</p>
            <span>{{ proveedor.detalle_direccion }}</span>
          </v-col>
          <v-col cols="12" md="6">
            <p class="mb-0 grey--text">Código de área</p>
            <span>{{ proveedor.codigo_area }}</span>
          </v-col>
          <v-col cols="12" md="6">
            <p class="mb-0 grey--text">Teléfono</p>
            <span>{{ proveedor.numero_telefono }}</span>
          </v-col>
          <v-col cols="12" md="12" class="mb-5">
            <p class="mb-0 grey--text">Correo</p>
            <span>{{ proveedor.correo_electronico }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" lg="6" v-if="!esTACOP">
        <div class="d-flex mb-4">
          <v-btn solid color="secondary" @click="descargarDeclaracionJurada()">
            <v-icon color="white">mdi-download</v-icon> Descargar
          </v-btn>
          <span class="ml-4">Si no se muestra el archivo, por favor descargarlo.</span>
        </div>
        <embed
          width="100%"
          height="80%"
          max-height="400px"
          :src="doc + '#page=1'"
          v-if="doc"
        />
      </v-col>
    </v-row>


    <v-row class="mt-8" v-if="!esTACOP">
      <v-col cols="4" sm="4" md="2">
        <v-btn
          class="mx-1"
          block
          outlined
          :disabled="
            proveedor.id_solicitud_proveedor_estado === 3 ? true : false
          "
          color="green"
          @click="accionModal(1)"
        >
          <v-icon color="green">mdi-send</v-icon>
          Aprobar
        </v-btn>
      </v-col>
      <v-col cols="4" sm="4" md="2">
        <v-btn
          class="mx-1"
          outlined
          block
          :disabled="
            proveedor.id_solicitud_proveedor_estado === 3 ? true : false
          "
          color="black"
          @click="accionModal(2)"
        >
          <v-icon>mdi-message-text</v-icon>
          Observar
        </v-btn>
      </v-col>
      <v-col cols="4" sm="4" md="2">
        <v-btn
          class="mx-1"
          outlined
          block
          :disabled="
            proveedor.id_solicitud_proveedor_estado === 3 ? true : false
          "
          color="red"
          @click="accionModal(3)"
        >
          <v-icon color="red">mdi-cancel</v-icon>
          Cancelar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4" md="2">
        <v-btn
          outlined
          block
          style="text-transform: none"
          color="primary"
          class="mt-4 mx-auto"
          to="/aprobacion-proveedor"
        >
          Volver
        </v-btn>
      </v-col>
    </v-row>

    <dialog-aprobar-proveedor
      v-if="code === 1 || code === 3"
      @cerrarDialog="cerraModal"
      :actionCode="code"
      :show="showDialog"
    />
    <dialog-observacion-proveedor
      v-if="code === 2"
      @cerrarDialog="cerraModal"
      :actionCode="code"
      :show="showObservacionDialog"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DialogAprobarProveedor from "./components/DialogAprobarProveedorComponent";
import DialogObservacionProveedor from "./components/DialogObservacionProveedorComponent";

export default {
  name: "revisionSolicitudProveedorView",
  components: {
    DialogAprobarProveedor,
    DialogObservacionProveedor,
  },
  data: () => ({
    showDialog: false,
    showObservacionDialog: false,
    code: null,
    tituloDialog: null,
    doc: null,
  }),
  computed: {
    ...mapState("aprobacionProveedor", [
      "id_request",
      "declaration",
      "proveedor",
      "lista_historial_observaciones",
    ]),
    idTributariaHandler() {
      switch (this.proveedor.id_identificacion_tributaria) {
        case 1:
          return "DUI";
        case 2:
          return "Pasaporte";
        case 3:
          return "NIT";
        case 4:
          return "NIT";
        case 5:
          return "Pasaporte";
        case 6:
          return "Carnet de residente";
        case 7:
          return "NIT";
        case 8:
          return "NIT";

        default:
          return "";
      }
    },
    esTACOP(){
      return this.haveRole("ROLE_TACOP")
    }
  },
  methods: {
    ...mapActions("aprobacionProveedor", [
      "getDeclaration",
      "getProveedor",
      "getListaTipoContribuyente",
      "getListaClasificacionEmpresarial",
      "getListaHistorialObservaciones",
    ]),
    accionModal(n) {
      if (n === 2) {
        this.showObservacionDialog = true;
      } else {
        this.showDialog = true;
      }
      this.code = n;
    },
    cerraModal() {
      this.showDialog = false;
      this.code = null;
    },
    getDoc(doc) {
      let b64 = Buffer.from(doc).toString("base64");
      this.doc = `data:application/pdf;base64,${b64}`;
    },
    async descargarDeclaracionJurada() {


      const blob = new Blob([Buffer.from(this.declaration.data)], {
        type: 'application/pdf',
      });

      const file = new File([blob], 'DeclaracionJurada', {
        type: 'application/pdf',
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
  },
  async mounted() {
    if (this.id_request === null) {
      this.$router.push("/aprobacion-proveedor");
    } else {
      await this.getProveedor(this.id_request);
      if (this.proveedor.id_solicitud_proveedor_estado === 5) {
        this.temporalAlert({
          show: true,
          message: "La solicitud ya fue cancelada",
          type: "error",
        });
        this.$router.push("/aprobacion-proveedor");
      }
      if (this.proveedor.id_solicitud_proveedor_estado === 4) {
        this.temporalAlert({
          show: true,
          message: "La solicitud ya fue aprobada",
          type: "error",
        });
        this.$router.push("/aprobacion-proveedor");
      }

      const response = await this.getDeclaration(this.id_request).catch(() => {

      });
      if (this.declaration === null) {
        this.temporalAlert({
          show: true,
          message: "Hubo un error al intentar obtener la carta jurada",
          type: "error",
        });
      } else {
        this.getDoc(this.declaration.data);
      }

    }
  },
};
</script>
