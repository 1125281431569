<template>
  <section>
    <p class="text-h6 secondary--text">
      Solicitudes de aprobación de proveedor
    </p>
    <v-row class="filters-row">
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @input="setFiltroNombre"
          @click:clear="setFiltroNombre(null), getListaProveedores()"
          @keyup="filterName"
          v-model="filtroNombreModel"
          outlined
          clearable
          label="Nombre proveedor o número documento"
        />
      </v-col>

      <v-col class="d-flex" cols="12" sm="6" md="2">
        <v-select
          :items="lista_estados_aprobacion"
          item-value="id"
          item-text="nombre"
          label="Estado"
          outlined
          clearable
          v-model="filtroIdEstadoModel"
          @click:clear="setFiltroEstado(null), getListaProveedores()"
          @change="setFiltroEstadoSolicitud"
        />
      </v-col>
      <v-col class="d-flex" cols="12" sm="6" md="2">
        <v-select
          :items="lista_tipo_controbuyente"
          item-value="id"
          item-text="nombre"
          label="Tipo de contribuyente"
          outlined
          clearable
          v-model="filtroTipoContribuyenteModel"
          @click:clear="setFiltroTipoContribuyente(null), getListaProveedores()"
          @change="setFiltroContribuyente"
        />
      </v-col>

      <v-col class="d-flex" cols="12" sm="6" md="2">
        <v-select
          :items="lista_clasificacion_empresarial"
          item-value="id"
          item-text="clasificacion"
          label="Clasificación empresarial"
          outlined
          clearable
          v-model="filtroClasificacionEmpresarialModel"
          @change="setFiltroClasificacionEmp"
          @click:clear="setFiltroClasificacion(null), getListaProveedores()"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-menu
          ref="menuDataRange"
          v-model="menuDataRange"
          :close-on-content-click="false"
          :return-value.sync="fechas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dateRangeText"
              label="Desde - Hasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="
                setFiltroFechaDesde(null),
                  setFiltroFechaHasta(null),
                  getListaProveedores()
              "
            />
          </template>
          <v-date-picker
            title="es"
            :max="fechaMaximaFiltro"
            v-model="fechas"
            range
            locale="es-Es"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuDataRange = false">
              Cancelar
            </v-btn>
            <v-btn
              :loading="disableButton"
              :disabled="disableButton"
              text
              color="primary"
              @click="$refs.menuDataRange.save(fechas), setFiltrosFechas()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <data-table-component
          v-models:select="porPaginaModel"
          v-models:pagina="paginaModel"
          :headers="headers"
          :items="lista_proveedores"
          @paginar="paginar"
          :total_registros="filters.total_rows"
        >
          <template v-slot:[`item.id_pais`]="{ item }">
            {{ item.id_pais === 68 ? "Salvadoreño" : "Extranjero" }}
          </template>
          <template v-slot:[`item.nombre`]="{ item }">
            {{ item.nombre.toUpperCase() }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <div>
              <v-chip
                label
                :color="item.color_estado_proveedor"
                class="black--text"
              >
                {{ item.estado_solicitud_proveedor }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <div class="d-flex justify-start">
              <v-tooltip
                v-if="
                  item.estado_solicitud_proveedor != 'Aprobado' &&
                  item.estado_solicitud_proveedor != 'Cancelado'
                "
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="30px"
                    color="primary"
                    @click="verProveedor(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span> Visualizar </span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  item.estado_solicitud_proveedor == 'Observado' &&
                  showResendEmail
                "
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="30px"
                    color="primary"
                    :disabled="esTACOP"
                    @click="resendEmailObservation(item)"
                  >
                    mdi-email-sync
                  </v-icon>
                </template>
                <span> Reenviar correo </span>
              </v-tooltip>
            </div>
          </template>
          <!-- Por alguna razon abajo esta el codigo duplicado -->
          <!-- <template v-slot:[`item.estado`]="{ item }">
            <div>
              <v-chip
                label
                :color="item.color_estado_proveedor"
                class="black--text"
                >{{ item.estado_solicitud_proveedor }}</v-chip
              >
            </div>
          </template> -->
        </data-table-component>
      </v-col>
    </v-row>

    <!-- footer -->
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <!-- boton que redirecciona a la vista anterior -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
    </footer>
  </section>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapMutations, mapState } from "vuex";
import AprobacionProveedores from "../../services/AprobacionProveedores.services";
import AppPaginationComponent from "../../components/AppPaginationComponent.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";

export default {
  components: { AppPaginationComponent, DataTableComponent },
  name: "aprobacionProveedorView",
  data: () => ({
    table: {
      select: 10,
      page: 3,
    },
    disableButton: false,
    fechas: ["", ""],
    menuDataRange: false,
    timer: null,
    reset: false,
    clasificacion_empresarial: null,
    headers: [
      { text: "No.", align: "start", sortable: true, value: "correlativo" },
      { text: "Proveedor", sortable: true, value: "nombre" },
      {
        text: "Tipo",
        sortable: true,
        value: "tipo_contribuyente",
      },
      { text: "Origen", sortable: true, value: "id_pais" },
      {
        text: "Tipo documento",
        sortable: true,
        value: "tipo_documento",
      },
      {
        text: "Número documento",
        align: "right",
        sortable: true,
        value: "numero_documento",
      },
      {
        text: "Clasificación empresarial",
        sortable: true,
        value: "empresa_clasificacion",
      },
      {
        text: "Fecha solicitud",
        sortable: true,
        value: "created_at",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "estado",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
    showResendEmail: true,
  }),
  computed: {
    ...mapState("aprobacionProveedor", [
      "lista_proveedores",
      "lista_tipo_controbuyente",
      "lista_clasificacion_empresarial",
      "filters",
      "lista_estados_aprobacion",
    ]),
    dateRangeText: {
      get() {
        return this.filters.fecha_desde == "" && this.filters.fecha_hasta == ""
          ? " "
          : moment(this.filters.fecha_desde).format("D/M/Y") +
              " - " +
              moment(this.filters.fecha_hasta).format("D/M/Y");
      },
      set(value) {},
    },
    filtroNombreModel: {
      get() {
        return this.filters.nombre;
      },
      set(value) {
        this.setFiltroNombre(value);
      },
    },
    filtroTipoContribuyenteModel: {
      get() {
        return this.filters.id_identificacion_tributaria;
      },
      set(value) {
        this.setFiltroTipoContribuyente(value);
      },
    },
    filtroClasificacionEmpresarialModel: {
      get() {
        return this.filters.id_clasificacion_empresa;
      },
      set(value) {
        this.setFiltroClasificacion(value);
      },
    },
    filtroIdEstadoModel: {
      get() {
        return this.filters.id_solicitud_proveedor_estado;
      },
      set(value) {
        this.setFiltroEstado(value);
      },
    },
    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
    fechaMaximaFiltro() {
      // Fecha actual en formato ISO 8601 format
      return moment().format("YYYY-MM-DD");
    },
    esTACOP(){
      return this.haveRole("ROLE_TACOP")
    }
  },
  methods: {
    ...mapActions("aprobacionProveedor", [
      "saveIdRequest",
      "getListaProveedores",
      "getListaTipoContribuyente",
      "getListaClasificacionEmpresarial",
      "getListaEstadosAprobacion",
      "ordenarPorProveedor",
      "limpiarFiltros",
    ]),
    ...mapMutations("aprobacionProveedor", [
      "setFiltroNombre",
      "setFiltroTipoContribuyente",
      "setFiltroClasificacion",
      "setFiltroFechaDesde",
      "setFiltroFechaHasta",
      "setFiltroEstado",
    ]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getListaProveedores();
    },
    obtener_color_estado(estado) {
      switch (estado) {
        case "Listo Revisión":
          return "#7D87A1";
        case "Aprobado":
          return "#C1ECD9";
        case "Cancelado":
          return "#FBD9D8";
        case "Observado":
          return "#FFEAA7";
        default:
          return "#D9D9D9";
      }
    },
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.getListaProveedores();
      }, 500);
    },
    setFiltroContribuyente() {
      this.getListaProveedores();
    },
    setFiltroClasificacionEmp() {
      this.getListaProveedores();
    },
    setFiltrosFechas() {
      this.setFiltroFechaDesde(this.fechas[0]);
      // Agregar 23:59:59 a la fecha hasta
      let fecha_hasta = this.fechas[1];
      if (fecha_hasta != "") {
        fecha_hasta = moment(fecha_hasta)
          .add(23, "hours")
          .add(59, "minutes")
          .add(59, "seconds")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      this.setFiltroFechaHasta(fecha_hasta);
      this.getListaProveedores();
    },
    setFiltroEstadoSolicitud() {
      this.getListaProveedores();
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getListaProveedores();
    },
    verProveedor(item) {
      if (item.id_solicitud_proveedor_estado === 4) {
        this.temporalAlert({
          show: true,
          message: "La solicitud ya fue aprobada",
          type: "success",
        });
      } else if (item.id_solicitud_proveedor_estado === 5) {
        this.temporalAlert({
          show: true,
          message: "La solicitud ya fue cancelada",
          type: "error",
        });
      } else {
        this.saveIdRequest(item.id);
        this.$router.push("/revision-proveedor");
      }
    },

    async resendEmailObservation(item) {
      if (item.id_solicitud_proveedor_estado == 3) {

        const response = await AprobacionProveedores.resendEmailProvider(
          item.id
        );

        if (response.status == 200) {
          this.temporalAlert({
            show: true,
            message: "Correo enviado correctamente",
            type: "success",
          });
          this.showResendEmail = false;
        } else {
          this.temporalAlert({
            show: true,
            message: "Error al enviar el correo",
            type: "error",
          });
        }


      }
    },
  },
  async created() {
    await this.getListaTipoContribuyente();
    await this.getListaClasificacionEmpresarial();
    await this.getListaProveedores();
    this.getListaEstadosAprobacion();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name !== "revision-proveedor") {
        vm.limpiarFiltros();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}

:deep(.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th.sortable) {
  text-align: center !important;
}
</style>
