<template>
  <v-dialog v-model="show" max-width="900" persistent>
    <v-card rounded="lg">
      <v-tabs v-model="tab" color="primary">
        <v-card-title>
          <v-tab>
            <v-icon color="primary">mdi-message-text</v-icon>
            Observación
          </v-tab>
          <v-tab v-if="lista_historial_observaciones.length > 0">
            <v-icon color="primary">mdi-history</v-icon>
            Historial
          </v-tab>
        </v-card-title>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-title class="d-flex justify-center primary--text">
            <p class="mt-5">{{ `¿Desea observar la solicitud?` }}</p>
          </v-card-title>
          <v-card-text class="d-flex justify-center secondary--text">
            <p>
              De confirmarse la siguiente acción los cambios no serán
              reversibles
            </p>
          </v-card-text>
          <v-card-text class="justify-center">
            <v-label>Observación *</v-label>
            <app-rich-text-component class="mt-5" placeholder="Escriba su observación" v-model="observacion">
            </app-rich-text-component>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <section id="scroll-target" style="max-height: 500px" class="overflow-y-auto">
            </section>
            <v-virtual-scroll :items="lista_historial_observaciones" height="500" item-height="330">
              <template v-slot:default="{ item }">
                <v-alert class="mx-5 my-5" border="left" colored-border color="primary accent-4" elevation="1">
                  <!-- 
                    item-height="350"
                  cambio en el nombre de encabezados del response del historial de observaciones
                  ademir rivera 2022-09-27
                  -- antiguo --
                    item.user_creation,
                    item.observation_text
                -->
                  <p>{{ item.created_at | moment("DD/MM/YYYY HH:mm A") }}</p>
                  <strong>{{ item.creacion_usuario }}</strong><br>
                  <p>{{ item.cargo }}</p>
                  <!-- texto estatico, debera integrarse el cargo -->
                  <!-- <p>Técnico dirección de compras</p> -->
                  <!--<div v-html="item.texto_observacion"></div>-->

                  <div class="container overflow-auto" v-html="item.texto_observacion" style=" height: 22vh;"></div>
                </v-alert>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="d-flex justify-center secondary--text">
        <v-btn :disabled="disableButton" color="secondary" class="mb-5" outlined @click="cerrarDialog()">
          Cancelar
        </v-btn>
        <v-btn :loading="disableButton" :disabled="disableButton" color="secondary" class="mb-5"
          @click="accionSolicitud()" v-if="tab !== 1">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "DialogObservacionProveedorComponent",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    actionCode: {
      typr: Number,
      required: true,
    },
  },
  data: () => ({
    disableButton: false,
    tab: null,
    benched: 0,
    tituloDialog: null,
    observacion: null,
  }),
  validations: {
    observacion: {
      required,
    },
  },
  computed: {
    ...mapState("aprobacionProveedor", [
      "id_request",
      "lista_historial_observaciones",
    ]),
    errorMessage() {
      const errors = [];
      if (!this.$v.observacion.$dirty) return errors;
      !this.$v.observacion.required &&
        errors.push("Debe agregar observaciones");
      return errors;
    },
  },
  methods: {
    moment,
    ...mapActions("aprobacionProveedor", [
      "getListaHistorialObservaciones",
      "updateEstadoSolicitudProveedor",
    ]),
    cerrarDialog() {
      this.$emit("cerrarDialog");
    },
    async accionSolicitud() {

      this.$v.$touch();
      if (!this.$v.$invalid) {
        let params = {
          id: this.id_request,
          /*
            cambio en el payload enviado al backend
            ademir rivera 2022-09-27
            -- antiguo --
            id_request_provider_status: 3,
            observation: this.observacion
          */
          body: {
            id_solicitud_proveedor_estado: 3,
            texto_observacion: this.observacion,
          },
        };
        this.disableButton = true;
        const response = await this.updateEstadoSolicitudProveedor(params)
        .catch(() => { this.disableButton = false 
        });
        if (response.status === 200 || response.status === 201) {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "success",
          });
          this.$emit("cerrarDialog");
          this.$router.push("/aprobacion-proveedor");
        } else {
          this.temporalAlert({
            show: true,
            message: "Hubó un error al actualizar el estado de la solicitud",
            type: "error",
          });
        }
      } else {
        this.temporalAlert({
          show: true,
          message: "Las observaciones son obligatorias",
          type: "error",
        });
      }

      this.disableButton = false;
    },
  },
  async mounted() {
    await this.getListaHistorialObservaciones(this.id_request);
  },
};
</script>
