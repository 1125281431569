<template>
  <v-dialog v-model="show" max-width="500" persistent>
    <v-card rounded="lg">
      <v-card-title class="d-flex justify-center primary--text">
        <p class="mt-5">{{ `¿Desea ${tituloDialog} la solicitud?` }}</p>
      </v-card-title>
      <v-card-text class="d-flex justify-center secondary--text">
        <p>De confirmarse la siguiente acción, los cambios no serán reversibles.</p>
      </v-card-text>
      <v-card-text class="d-flex justify-center">
        <v-textarea
          :error-messages="motivoCancelacionError"
          v-if="cancelMode"
          v-model="motivo_cancelacion"
          label="Motivo*"
          outlined
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-center secondary--text">
        <v-btn
          :disabled="loading"
          color="primary"
          class="mb-5"
          outlined
          @click="cerrarDialog()"
        >
          Cancelar
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          class="mb-5"
          @click="accionSolicitud()"
        >
          Continuar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
// Importamos vuelidate
import {
  minLength,
  maxLength,
  requiredIf,
  helpers,
} from "vuelidate/lib/validators";
const letter = helpers.regex(
  "letter",
  /^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ,.&üÜ:;()_\-' ]*$/
);

export default {
  name: "DialogAprobarProveedorComponent",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    actionCode: {
      typr: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    tituloDialog: null,
    cancelMode: false,
    motivo_cancelacion: null,
  }),
  validations: {
    motivo_cancelacion: {
      letter,
      requiredIf: requiredIf((vm) => vm.cancelMode),
      minLength: minLength(10),
      maxLength: maxLength(200),
    },
  },
  computed: {
    ...mapState("aprobacionProveedor", ["id_request"]),
    motivoCancelacionError() {
      const errors = [];
      if (!this.$v.motivo_cancelacion.$dirty) return errors;
      !this.$v.motivo_cancelacion.requiredIf &&
        errors.push("El motivo es requerido");
      !this.$v.motivo_cancelacion.minLength &&
        errors.push("El motivo debe tener al menos 10 caracteres");
      !this.$v.motivo_cancelacion.maxLength &&
        errors.push("El motivo debe tener máximo 200 caracteres");
      !this.$v.motivo_cancelacion.letter &&
        errors.push("El motivo no puede contener caracteres especiales");
      return errors;
    },
  },
  methods: {
    ...mapActions("aprobacionProveedor", ["updateEstadoSolicitudProveedor"]),
    cerrarDialog() {
      this.$emit("cerrarDialog");
    },
    setDialogMessage() {
      if (this.actionCode === 1) {
        this.tituloDialog = "aprobar";
        this.cancelMode = false;
      }
      if (this.actionCode === 3) {
        this.tituloDialog = "cancelar";
        this.cancelMode = true;
      }
    },
    async accionSolicitud() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        let params = {
          id: null,
          body: {
            id_solicitud_proveedor_estado: null,
            motivo_cancelacion: null,
          },
        };
        params.id = this.id_request;
        if (this.actionCode === 1) {
          params.body = {
            id_solicitud_proveedor_estado: 4,
          };
        }
        if (this.actionCode === 3) {
          if (this.motivo_cancelacion !== null) {
            params.body = {
              id_solicitud_proveedor_estado: 5,
              motivo_cancelacion: this.motivo_cancelacion,
            };
          } else {
            this.temporalAlert({
              show: true,
              message: "El campo motivo es obligatorio",
              type: "error",
            });
            this.loading = false;
          }
        }
        const response = await this.updateEstadoSolicitudProveedor(
          params
        ).catch(() => {
          this.loading = false;
        });
        if (response.status === 200 || response.status === 201) {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "success",
          });
          this.$emit("cerrarDialog");
          this.$router.push("/aprobacion-proveedor");
        } else {
          this.temporalAlert({
            show: true,
            message: "Hubó un error al actualizar el estado de la solicitud",
            type: "error",
          });
        }
        this.loading = false;
      }
    },
  },
  created() {
    this.setDialogMessage();
  },
};
</script>
